<template>
    <div>
        <h2 class="mb-1">Create new client</h2>
        <b-card>
            <b-tabs>
                <b-tabs
                    vertical
                    nav-wrapper-class="nav-vertical"
                >
                    <b-tab
                        active
                        title="Client Details"
                    >
                        <validation-observer ref="addClientRules">
                            <b-form
                                class="px-2"
                            >
                                <!-- Details -->
                                <h4>Details</h4>
                                <hr>
                                <!-- first and last name -->
                                <b-row>
                                    <b-col md="6">
                                        <!-- First Name -->
                                        <b-form-group
                                            label-for="First name"
                                        >
                                        <template v-slot:label>
                                            First name <span class="text-danger">*</span>
                                        </template>
                                            <validation-provider
                                                #default="{ errors }"
                                                name="First name"
                                                rules="required"
                                            >
                                                <b-form-input
                                                    id="full-name"
                                                    v-model="firstName"
                                                    trim
                                                    :state="errors.length > 0 ? false:null"
                                                    placeholder="First name"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="6">
                                        <!-- Last Name -->
                                        <b-form-group
                                            label-for="Last name"
                                        >
                                        <template v-slot:label>
                                            Last name <span class="text-danger">*</span>
                                        </template>
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Last name"
                                                rules="required"
                                            >
                                                <b-form-input
                                                    id="full-name"
                                                    v-model="lastName"
                                                    trim
                                                    :state="errors.length > 0 ? false:null"
                                                    placeholder="Last name"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <!-- Date Of Birth -->
                                <b-col md="6" class="pl-0">
                                    <b-form-group
                                        label-for="Date Of Birth"
                                    >
                                    <template v-slot:label>
                                        Date Of Birth <span class="text-secondary">(e.g. December 12, 1930)</span>
                                    </template>
                                        <date-picker
                                            v-model="dateOfBirth"
                                            placeholder="Select a Date"
                                            type="date"
                                            format="MMM DD, Y"
                                            value-type="MM-DD-YYYY"
                                            style="max-width: 100% !important; width: 100% !important;"
                                        ></date-picker>
                                    </b-form-group>
                                </b-col>
                                
                                <b-row>
                                    <!-- County of Residence -->
                                    <b-col :md="countryOfResidence === 'Other' ? '6' : '12'">
                                        <b-form-group
                                            label="County"
                                            label-for="County"
                                        >
                                            <v-select
                                                v-model="countryOfResidence"
                                                placeholder="County"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsCountryOfResidence"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6" v-if="countryOfResidence === 'Other'">
                                        <!-- Other -->
                                        <b-form-group
                                            label="Other"
                                            label-for="Other"
                                        >
                                            <b-form-input
                                                v-model="countyOther"
                                                trim
                                                placeholder="Other"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <!-- email and phone -->
                                <b-row>
                                    <b-col md="6">
                                        <!-- Email -->
                                        <b-form-group
                                            label-for="email"
                                        >
                                        <template v-slot:label>
                                            Email Address
                                        </template>
                                            <!-- <validation-provider
                                                #default="{ errors }"
                                                name="Email"
                                                rules="required|email"
                                            > -->
                                                <b-form-input
                                                    id="email"
                                                    v-model="email"
                                                    trim
                                                    placeholder="Email Address"
                                                />
                                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                            <!-- </validation-provider> -->
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="6">
                                        <!-- Phone -->
                                        <b-form-group
                                            label="Phone Number"
                                            label-for="phone"
                                        >
                                            <b-form-input
                                                id="phone"
                                                v-model="phone"
                                                trim
                                                placeholder="Phone Number"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                
                                <!-- insurance type and primary language -->
                                <b-row>
                                    <b-col md="6">
                                        <!-- Insurance types accepted -->
                                        <b-form-group
                                            label="Insurance types"
                                            label-for="Insurance types"
                                        >
                                            <v-select
                                                v-model="insuranceType"
                                                placeholder="Insurance types"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsInsuranceType"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="6">
                                        <!-- Languages -->
                                        <b-form-group
                                            label="Primary Language"
                                            label-for="primary language"
                                        >
                                            <v-select
                                                v-model="primaryLanguage"
                                                placeholder="Primary Language"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsPrimaryLanguage"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <!-- race and household income -->
                                <!-- <b-row>
                                    <b-col md="6">
                                        <b-form-group
                                            label="Race"
                                            label-for="Race"
                                        >
                                            <v-select
                                                v-model="race"
                                                placeholder="Race"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsRace"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="6">
                                        <b-form-group
                                            label="Household Income"
                                            label-for="Household Income"
                                        >
                                            <v-select
                                                v-model="householdIncome"
                                                placeholder="Household Income"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsHouseholdIncome"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row> -->

                                <!-- Gambling Types and Employment Status -->
                                <!-- <b-row>
                                    <b-col md="6">
                                        <b-form-group
                                            label="Gambling Types"
                                            label-for="Gambling Types"
                                        >
                                            <v-select
                                                v-model="gamblingTypes"
                                                placeholder="Gambling Types"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsGamblingTypes"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="6">
                                        <b-form-group
                                            label="Employment Status"
                                            label-for="Employment Status"
                                        >
                                            <v-select
                                                v-model="employmentStatus"
                                                placeholder="Employment Status"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsEmploymentStatus"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row> -->

                                <!-- Form Actions -->
                                <div class="d-flex mt-2 float-right">
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="primary"
                                        class="mr-2"
                                        type="submit"
                                        @click.prevent="validationForm"
                                    >
                                    Save
                                    </b-button>
                                </div>
                            </b-form>
                        </validation-observer>
                    </b-tab>
                </b-tabs>
            </b-tabs>
        </b-card>

        <b-modal
            id="modal-no-backdrop"
            ok-only
            content-class="shadow"
            title="Please check to make sure this is not a duplicate entry"
            ref="modal-confirm"
            :hide-footer="true"
            :hideHeaderClose="false"
            :no-close-on-backdrop="true"
            :close-on-backdrop="false"
            :close-on-esc="false"
            cancel-variant="outline-secondary"
            centered
        >
            <b-row>
                <b-col md="6">
                    <b-button
                        variant="primary"
                        class="btn mt-1"
                        style="padding: 11px 20px;"
                        :to="{ name: 'edit-client', params: {id: idRouteClient} }"
                    >
                    Edit Existing Client Record
                    </b-button>
                </b-col>
                <b-col md="6">
                    <b-button
                        variant="primary"
                        class="btn mt-1"
                        @click="createClientFunction"
                    >
                    Continue and Add New Client Record
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BTabs, BTab, BCardText, BCard, BCol, BRow, BFormTextarea,
  BModal, VBModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { mapActions } from 'vuex'
import { db } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            countries,
            formSubmit: false,
            
            // fields db taxonomies clients
            optionsCountryOfResidence: [],
            optionsInsuranceType: [],
            optionsPrimaryLanguage: [],
            optionsRace: [],
            optionsHouseholdIncome: [],
            optionsGamblingTypes: [],
            optionsEmploymentStatus: [],
            optionsEducationLevels: [],
            optionsInvolvedWithRecoveryGroups: [],
            optionsGender: [],

            // fields form details client
            firstName: '',
            lastName: '',
            dateOfBirth: null,
            countryOfResidence: '',
            countyOther: '',
            email: '',
            phone: '',
            insuranceType: '',
            primaryLanguage: '',
            race: '',
            householdIncome: '',
            gamblingTypes: '',
            employmentStatus: '',
            educationLevels: '',
            involvedWithRecoveryGroups: '',
            gender: '',
            
            rules: {
                required: v => !!v || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                email: v => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(v) || 'Email not valid.'
                },
            },
            idRouteClient: '',
        }
    },
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BCardText,
        BTabs,
        BTab,
        BCard,
        BCol,
        BRow,
        BFormTextarea,
        BModal,
        vSelect,

        // date picker
        flatPickr,
        DatePicker,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    methods: {
        ...mapActions(['createClient']),
        validationForm() {
            this.$refs.addClientRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.submitClient()
                }
            })
        },
        createClientFunction() {
            // console.log('first')
            let objectClient = {
                firstName: this.firstName,
                lastName: this.lastName,
                dateOfBirth: this.dateOfBirth,
                countryOfResidence: this.countryOfResidence,
                countyOther: this.countyOther,
                email: this.email,
                phone: this.phone,
                insuranceType: this.insuranceType,
                primaryLanguage: this.primaryLanguage,
                // race: this.race,
                // householdIncome: this.householdIncome,
                // gamblingTypes: this.gamblingTypes,
                // employmentStatus: this.employmentStatus,
                // educationLevels: this.educationLevels,
                involvedWithRecoveryGroups: this.involvedWithRecoveryGroups,
                gender: this.gender,
                status: 'active',

                role: 'client',
            }
            this.createClient(objectClient)
            this.$refs.addClientRules.reset()
            this.firstName = ''
            this.lastName = ''
            this.dateOfBirth = null,
            this.countryOfResidence = ''
            this.countyOther = ''
            this.email = ''
            this.phone = ''
            this.insuranceType = ''
            this.primaryLanguage = ''
            this.race = ''
            this.householdIncome = ''
            this.gamblingTypes = ''
            this.employmentStatus = ''
            this.educationLevels = ''
            this.involvedWithRecoveryGroups = ''
            this.gender = ''
            this.$refs.addClientRules.reset()
            this.formSubmit = false

            this.$refs['modal-confirm'].hide()
        },
        submitClient() {
            this.formSubmit = true

            // check if the customer already exists registered with dateOfBirth and name
            db.collection('clients').where('dateOfBirth', '==', this.dateOfBirth).get()
            .then(getClients => {
                if(getClients.size >= 1) {
                    var existData = false
                    getClients.forEach(client => {
                        var nombre = client.data().firstName.toLowerCase().trim() + ' ' + client.data().lastName.toLowerCase().trim()
                        var nombreAtomic = this.firstName.toLowerCase().trim() + ' ' + this.lastName.toLowerCase().trim()
                        var county = client.data().countryOfResidence ? client.data().countryOfResidence.toLowerCase() : ''
                        var countryOfResidenceAtomic = this.countryOfResidence ? this.countryOfResidence.toLowerCase() : ''
                        // console.log(nombre,nombreAtomic)
                        // if(nombre === nombreAtomic && county === countryOfResidenceAtomic) {
                        if(nombre === nombreAtomic) {
                            if(!existData) {
                                // this.$toast({
                                //     component: ToastificationContent,
                                //     position: 'top-right',
                                //     props: {
                                //         title: `Please check to make sure this is not a duplicate entry.`,
                                //         icon: 'CoffeeIcon',
                                //         variant: 'success',
                                //     },
                                // })
                                this.idRouteClient = client.id
                                this.$refs['modal-confirm'].show()
                            }
                            
                            existData = true
                        }
                    })

                    if(!existData) {
                        let objectClient = {
                            firstName: this.firstName,
                            lastName: this.lastName,
                            dateOfBirth: this.dateOfBirth,
                            countryOfResidence: this.countryOfResidence,
                            countyOther: this.countyOther,
                            email: this.email,
                            phone: this.phone,
                            insuranceType: this.insuranceType,
                            primaryLanguage: this.primaryLanguage,
                            // race: this.race,
                            // householdIncome: this.householdIncome,
                            // gamblingTypes: this.gamblingTypes,
                            // employmentStatus: this.employmentStatus,
                            // educationLevels: this.educationLevels,
                            involvedWithRecoveryGroups: this.involvedWithRecoveryGroups,
                            gender: this.gender,
                            status: 'active',

                            role: 'client',
                        }
                        this.createClient(objectClient)
                        this.$refs.addClientRules.reset()
                        this.firstName = ''
                        this.lastName = ''
                        this.dateOfBirth = null,
                        this.countryOfResidence = ''
                        this.countyOther = ''
                        this.email = ''
                        this.phone = ''
                        this.insuranceType = ''
                        this.primaryLanguage = ''
                        this.race = ''
                        this.householdIncome = ''
                        this.gamblingTypes = ''
                        this.employmentStatus = ''
                        this.educationLevels = ''
                        this.involvedWithRecoveryGroups = ''
                        this.gender = ''
                        this.$refs.addClientRules.reset()
                        this.formSubmit = false
                        this.$refs['modal-confirm'].hide()
                    }
                } else {
                    let objectClient = {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        dateOfBirth: this.dateOfBirth,
                        countryOfResidence: this.countryOfResidence,
                        countyOther: this.countyOther,
                        email: this.email,
                        phone: this.phone,
                        insuranceType: this.insuranceType,
                        primaryLanguage: this.primaryLanguage,
                        // race: this.race,
                        // householdIncome: this.householdIncome,
                        // gamblingTypes: this.gamblingTypes,
                        // employmentStatus: this.employmentStatus,
                        // educationLevels: this.educationLevels,
                        involvedWithRecoveryGroups: this.involvedWithRecoveryGroups,
                        gender: this.gender,
                        status: 'active',

                        role: 'client',
                    }
                    this.createClient(objectClient)
                    this.$refs.addClientRules.reset()
                    this.firstName = ''
                    this.lastName = ''
                    this.dateOfBirth = null,
                    this.countryOfResidence = ''
                    this.countyOther = ''
                    this.email = ''
                    this.phone = ''
                    this.insuranceType = ''
                    this.primaryLanguage = ''
                    this.race = ''
                    this.householdIncome = ''
                    this.gamblingTypes = ''
                    this.employmentStatus = ''
                    this.educationLevels = ''
                    this.involvedWithRecoveryGroups = ''
                    this.gender = ''
                    this.$refs.addClientRules.reset()
                    this.formSubmit = false
                    this.$refs['modal-confirm'].hide()
                }
            })
        
        }
    },
    created() {
        db.collection('taxonomies').doc('client').get()
        .then(docTaxonomia => {
            this.optionsCountryOfResidence = docTaxonomia.data().countryOfResidence
            this.optionsInsuranceType = docTaxonomia.data().insuranceType
            this.optionsPrimaryLanguage = docTaxonomia.data().primaryLanguage
            this.optionsRace = docTaxonomia.data().race
            this.optionsHouseholdIncome = docTaxonomia.data().householdIncome
            this.optionsGamblingTypes = docTaxonomia.data().gamblingTypes
            this.optionsEmploymentStatus = docTaxonomia.data().employmentStatus
            this.optionsEducationLevels = docTaxonomia.data().educationLevels
            this.optionsInvolvedWithRecoveryGroups = docTaxonomia.data().involvedWithRecoveryGroups
            this.optionsGender = docTaxonomia.data().gender
        })
    },
    // mounted() {
    //     flatpickr('.flatpickr', {
    //     allowInput: true,
    //     onBlur: function(value) {
    //         console.log('input exited', value)
    //     },
    //     onChange: function(ev) {
    //         console.log('date selected', ev);
    //     }
    //     });
    // }
}
</script>

<style>
.addClient-button {
    width: 180px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>